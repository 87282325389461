
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "lib/RemoveNullObjectValue";
import useQueryString from "hooks/useQueryString";
import { t } from "i18next";
import { useUuid } from "_context/uuid/uuidProvider";
import * as api from "../../api/api";
import { useQuery } from "react-query";
import { useCarts } from "_context/shoppingCart/cartProvider";
import SearchBarCard from 'components/SearchBarCard';
import useClickTarget from "hooks/useClickTarget";
import Icons from "reuse/Icons";
import notfoundImage from "assets/notfound.png"


const SearchBar = ({ inputProps, reference }) => {

    const [search, setSearch] = useState('')
    const [searchText, setSearchText] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const { addCart, removeItem, cart } = useCarts();


    const dropdownRef = useRef(null);

    const target = useClickTarget(dropdownRef, showPopup, setShowPopup);

    const handleAddCart = (param) => {
        addCart(param);
    };

    const handleRemoveCart = (param) => {
        removeItem(param);
    };

    const { data: uuid } = useUuid();
    let {
        data: { result } = {},
        isError,
        error,
        isLoading,
        isFetching,
    } = useQuery(
        ["getHotelMenus", search],
        () => api.getHotelMenus(uuid, search),
        {
            enabled: (!!uuid && !!search),
            staleTime: 100000,
        }
    );
    console.log({ result, search });

    const inputInterval = useRef()
    const ref = useRef()
    reference = ref
    // const location = useLocation();

    // const changeUrl = (params) => ({
    //     pathname: location.pathname,
    //     search: new URLSearchParams(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
    // });

    // const navigate = useNavigate();
    // const navigateUrl = (obj) => navigate(changeUrl({ ...obj, page: 1 }));

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchText(value)
        clearTimeout(inputInterval.current)
        inputInterval.current = setTimeout(() => {
            setSearch(value)
        }, 1000);
    }

    useEffect(() => {
        if (search) setShowPopup(true)
    }, [search])

    const onKeyDown = (e) => {
        console.log({ e });
        inputProps?.onKeyDown?.(e)
        if (e.keyCode === 13) {
            setShowPopup(false)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.searchBox}>
                <Icons.Search />
                <input
                    placeholder={t("searchHome")}
                    type="text"
                    className={["form-control border-success", styles.input, showPopup && styles.open].join(" ")}
                    name="search-bar"
                    onChange={handleChange}
                    ref={ref}
                    // defaultValue={search}
                    {...(inputProps && inputProps)}
                    onKeyDown={onKeyDown}
                    value={searchText}
                />
                {search ? <button type="button" className={styles.closeIcon} aria-label="Close" onClick={() => {
                    setShowPopup(false)
                    setSearchText("")
                }}>
                    <span aria-hidden="true">&times;</span>
                </button> : ""}
            </div>

            <div className={styles.searchBarContainer} >

                {showPopup ?
                    <div className={styles.searchBarBox} ref={dropdownRef}>

                        {/* <div>
                            <div>{t("searchAuthor", { author: search })}</div>

                        </div> */}
                        <div className=" g-4 mt-3 px-2 ">
                            {
                                // !result?.length
                                //  && isFetching ?

                                // <div className={styles.notfound}>{t("loading")}</div> :
                                !result?.length && !isFetching ?
                                    <div className={styles.notfound}>
                                        <div className={styles.notfoundImage}>
                                            <img src={notfoundImage} />
                                        </div>
                                        <div className={styles.notfoundText}>
                                            {t("notfound")}
                                        </div>
                                    </div>
                                    :

                                    (
                                        result ||
                                        new Array(10).fill())?.map((x) => {
                                            const { product, id, ...other } = x || {};
                                            const params = {
                                                menuId: id,
                                                ...product,
                                                ...other,
                                            };
                                            const isToCart = cart?.object[params?.id];
                                            if (isToCart) {
                                                const shoppingItem = cart?.shoppingCart?.find(
                                                    (o) => o?.data?.id === params?.id
                                                );

                                                params["count"] = shoppingItem?.count;
                                            }

                                            return (

                                                <div className="row g-4  mt-1">
                                                    <SearchBarCard
                                                        key={id}
                                                        handleRemoveCart={handleRemoveCart}
                                                        handleAddCart={handleAddCart}
                                                        isToCart={isToCart}
                                                        data={params}
                                                        loading={!x}
                                                    />
                                                </div>
                                            )
                                        })
                            }
                        </div>
                    </div>
                    : ""
                }
            </div>
        </div>
    )
}

export default SearchBar;