import axios from "axios";
import configs from "../config";
const { API_URL } = configs.data
const baseApi = axios.create({
  baseURL: API_URL,
});
// -------------------------------------------------------------------------------- GET
export const getProduct = (uuid, id) =>
  baseApi.get(`${API_URL}/v1/products/${id}/${uuid}`).then((res) => res.data);
// ----------------------------------------
export const getRecommendedProduct = (uuid, params) =>
  baseApi
    .get(`${API_URL}/v1/hotels/menu/recommended/${uuid}`, { params })
    // .get(`${API_URL}/v1/products/recommended/${uuid}`, { params })
    .then((res) => res.data);
export const getWeather = () =>
  baseApi.get(`${API_URL}/v1/weather`).then((res) => res.data);
// ----------------------------------------
export const getHotel = (uuid) =>
  baseApi.get(`${API_URL}/v1/hotels/${uuid}`).then((res) => res.data);
// ----------------------------------------
export const getHotelMenus = (uuid, search, params) =>
  baseApi
    .get(`${API_URL}/v1/hotels/menu/search/${uuid}/${search}`, { params })
    .then((res) => res.data);
// ----------------------------------------
export const getHotelMenu = (uuid, id, params) =>
  baseApi
    .get(`${API_URL}/v2/hotels/menu/${uuid}`, { params })
    // .get(`${API_URL_V2}hotels/menu/${id}/${uuid}`, { params })
    .then((res) => res.data);
// ----------------------------------------
export const getHotelMenuWithBanner = (uuid, id, params) =>
  baseApi
    .get(`${API_URL}/v1/hotels/menu/banners/${id}/${uuid}`, { params })
    // .get(`${API_URL_V2}hotels/menu/${id}/${uuid}`, { params })
    .then((res) => res.data);

// ----------------------------------------
export const getCategories = (uuid) =>
  baseApi
    .get(`${API_URL}/v1/product-categories/by-hotel/${uuid}`)
    .then((res) => res);
// ----------------------------------------
export const getParentCategories = (uuid, source, isNewUser, roomNumber, language) =>
  baseApi
    .get(`${API_URL}/v1/product-categories/by-hotel/${uuid}`, {
      // params: { isCatalog, isMagnet, isNewUser },
      params: { ...(source && { source }), isNewUser, ...(roomNumber && { roomNumber }), ...(language && { language }) },
    })
    .then((res) => res);
export const getBanner = (uuid) =>
  baseApi
    .get(`${API_URL}/v1/product-categories/banners/${uuid}`)
    .then((res) => res.data);

// ----------------------------------------
export const getChildCategories = (uuid, id) =>
  baseApi
    .get(`${API_URL}/v1/product-categories/children/${id}/${uuid}`)
    .then((res) => res);

export const getDolarRate = () =>
  baseApi.get(`${API_URL}/v1/currencies`).then((res) => res.data);
// ----------------------------------------

export const postOrders = (uuid, params, isCatalog, isMagnet) => {
  return baseApi
    .post(`${API_URL}/v1/orders/${uuid}`, params, {
      params: { isCatalog, isMagnet },
    })
    .then((res) => res);
};
// ----------------------------------------
export const getDiscount = (code) =>
  baseApi.get(`${API_URL}/v1/discounts/${code}`).then((res) => res.data);
// -------------------------------------------------------------------------------- POST

export default baseApi;
