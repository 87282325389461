import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./product.module.scss";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
const { useTranslation } = require("react-i18next");

function ModalProduct({ show, setShow, handleRemoveCart, handleAddCart, addCount, data, textTranslate, isToCart, symbol }) {
	const { t, i18n } = useTranslation();

	let limitedPromotion = false;
	if ((data?.promotionCodes || data?.isCampaign) && data?.count == 1) limitedPromotion = true;

	// console.log({ data });
	return (
		<>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				centered={false}
				style={{
					top: "0",
				}}>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.close} onClick={() => setShow(false)}>
						<span aria-hidden="true">&times;</span>
					</div>
					<div className="d-flex flex-column">
						<div className={styles.image}>
							<img src={data?.images} className="w-100" alt={data?.name} />
						</div>
						<div className={styles.content}>
							<div>
								<h2>{textTranslate?.name || data?.name}</h2>
								<p>{textTranslate?.shortDesc || data?.shortDesc}</p>
								<p>{textTranslate?.desc || data?.desc}</p>
								<div className="d-flex flex-row align-items-center">
									{data?.oldPrice ? (
										<h4 className="orgPrice mr-2">
											{numberWithCommas(changePriceFormat(data?.oldPrice))} {symbol}
										</h4>
									) : (
										""
									)}
									<h4 className="price ">
										{numberWithCommas(changePriceFormat(data?.price))} {symbol}
									</h4>
								</div>
							</div>
							<div className={styles.parentElement}>
								<span
									className={`d-flex align-items-center justify-content-center ${styles.element} ${styles.changeCartButtonsParent} ${
										isToCart ? styles.show : styles.hide
									}`}>
									{data?.count > 1 ? (
										<button
											type="button"
											className=" dec quantityButtonMinus comeTop"
											id="adetEksi"
											data-urunid={19}
											disabled={data?.isCampaign}
											onClick={() => !data?.isCampaign && addCount(data, data?.count - 1)}>
											<i className="fa-solid fa-minus" />
										</button>
									) : (
										<button
											type="button"
											className=" dec quantityButtonMinus comeTop"
											disabled={data?.isCampaign}
											id="adetEksi"
											data-urunid={19}
											onClick={() => !data?.isCampaign && handleRemoveCart(data)}>
											<i className="fa-solid fa-minus" />
										</button>
									)}

									<Button className="quantityButton">{data?.count}</Button>
									<button
										type="button"
										className="  inc quantityButtonPlus"
										id="adetArti"
										data-urunid={19}
										disabled={limitedPromotion}
										onClick={() => {
											!limitedPromotion && addCount(data);
										}}>
										{" "}
										<i className="fa-solid fa-plus" />
									</button>
								</span>
								<div
									className={`d-grid gap-2  ${styles.element} ${styles.button} ${!isToCart ? styles.show : styles.hide}`}
									onClick={() => handleAddCart?.(data)}>
									<Button variant="success" size="md">
										{t("addToBasket")}
									</Button>
								</div>
								{/* )} */}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ModalProduct;
