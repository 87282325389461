import React, { useEffect, useRef, useState } from "react";
import { LANGUAGE_DATA, LANGUAGE_DATA_OBJECT, UUID_KY } from "../../enumeration";
import Select from "react-select";
import "./style.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { useTranslation } from "react-i18next";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import flag from "../../assets/united-states 1.png";
import { Link } from "react-router-dom";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import Marquee from "react-fast-marquee";
import MarqueeComponent from "components/MarqueeComponent";

const NavBar = () => {
	const { data: uuid } = useUuid();
	let { data, isError, error, isLoading } = useQuery("hotel", () => getHotel(uuid), {
		enabled: !!uuid,
	});
	const { setLanguage } = useUtmSource();

	const { t, i18n } = useTranslation();
	console.log({ i18n });

	const changeLanguageHandler = (selectedOption) => {
		const value = selectedOption.value;
		i18n.changeLanguage(value);
		setLanguage(value);
	};
	// const validLanguage = LANGUAGE_DATA.filter((x) => {
	//   const valid = data?.languages.find(y => y.code == x.value)
	//   if (!valid) return false
	//   return true;
	// })
	const languageOptions = data?.languages?.map((x) => {
		return {
			value: x.code,
			label: (
				<div className="langOptions">
					{x.image ? <img src={x.image} alt={x.name} className="languageOptionImage" /> : ""}
					{x.name}
				</div>
			),
		};
	});
	// isLoading = true
	const value = languageOptions?.find((x) => x.value === i18n.language);

	useEffect(() => {
		if (!data) return;
		if (!LANGUAGE_DATA_OBJECT[i18n.language]) {
			if (LANGUAGE_DATA_OBJECT[data?.defaultLanguage]) i18n.changeLanguage(data?.defaultLanguage);
			else i18n.changeLanguage(LANGUAGE_DATA[0]?.value);
		}
	}, [data]);

	console.log({ data, languageOptions, value });

	return (
		<header className="d-flex px-1">
			<nav className="navbar navbar-expand-lg">
				<div className="navbarSpace d-flex align-items-center w-100 justify-content-between">
					<div className="navBarBrand">
						{isLoading ? (
							<Skeleton width={70} height={15} />
						) : (
							<Link className=" d-flex align-items-center mr-1" to={"/"}>
								<MarqueeComponent text={data?.name} />
							</Link>
						)}
					</div>

					{isLoading ? (
						<Skeleton width={100} height={35} />
					) : data ? (
						<Select
							className="languageSelect"
							options={languageOptions}
							value={value}
							onChange={changeLanguageHandler}
							preventScroll={true} // Disable screen zoom on select
							isSearchable={false} //
						/>
					) : (
						""
					)}
				</div>
			</nav>
		</header>
	);
};

export default NavBar;
