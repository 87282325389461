import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { useCarts } from "_context/shoppingCart/cartProvider";
import styles from "./index.module.scss";
import Icons from "reuse/Icons";


const BottomNav = () => {
  const { cart } = useCarts();
  console.log("badge==>", cart?.shoppingCart);
  const totalCount = cart?.shoppingCart?.reduce(
    (accumulator, currentData) => accumulator + currentData.count,
    0
  );

  return (
    <nav className="bottom-navigation">
      <Link
        className="text-white small font-weight-bold text-decoration-none"
        to={{
          pathname: "/cart",
          // search: `?${[UUID_KY]}=${uuid}`,
        }}
      >
        <span className="circle">
          <i className="fa-solid fa-cart-shopping icon"></i>

          <span
            className={`bottomBadge badge-secondary bottom-cart ${!cart.shoppingCart.length ? "fa-beat-fade" : ""
              }`}
            id="spnSepetAdet"
          >
            {totalCount || 0}
          </span>
          {/* <div className={styles.box}>
            <div className={[styles.heart, !cart.shoppingCart.length ? "fa-beat-fade" : ""].join(" ")}>
              <Icons.HeartFill />
              <div className={styles.number}>
                {totalCount || 0}
              </div>
            </div>
          </div> */}

          {/* {cart?.shoppingCart?.length > 0 ? (
            <span
              className={`bottomBadge badge-secondary bottom-cart ${
                cart.shoppingCart.length === 0 ? "fa-beat-fade" : ""
              }`}
              id="spnSepetAdet"
            >
              {totalCount}
            </span>
          ) : (
            <span
              className={`bottomBadge badge-secondary bottom-cart ${
                cart.shoppingCart.length === 0 ? "fa-beat-fade" : ""
              }`}
              id="spnSepetAdet"
            >
              0
            </span>
          )} */}
        </span>
      </Link>
    </nav>
  );
};

export default BottomNav;
