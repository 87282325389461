const baseStrings = {
	translation: {
		home: "главная страница",
		menu: "меню",
		opportunity: "Специальный",
		opportunityProducts: "специальные предложения",
		payment: "Оплата",
		back: " Назад ",
		yourName: "Ваше имя",
		roomNumber: "Номер комнаты",
		deliveryInfo: "информация о гостях",
		yourDeliveryInfo: "информация о гостях",
		paymentInfo: "информация об оплате",
		productTotal: "общая сумма товаров",
		serviceFee: "плата за обслуживание",
		total: "Общая сумма",
		online: "Онлайн",
		card: "Кредитная карта при доставке",
		myCart: "моя корзина",
		cash: "наличные",
		note: "заметка",
		delivery: "доставка",
		payment: "Оплата",
		orderNote: "Сообщите ваше примечание к заказу",
		completeOrder: "Завершить заказ",
		enjoyYourMeal: "Приятного аппетита!",
		yourOrderReceived: "Ваш заказ получен",
		// completeOrder: "Ваш заказ завершен",
		hello: "Здравствуйте",
		uuiError: "Вы можете ввести его, просто отсканировав QR-код",
		successful: "Успешный",
		productAddToCart: "Товар добавлен в корзину!",
		addToBasket: "Добавить в корзину",
		removeFromBasket: "Удалить из корзины",
		productTitle: "личный уход",
		itemNotFound: "Товар не найден",
		completeTheOrder: "Завершите заказ",
		selectThePayment: "Пожалуйста,выберите способ оплаты",
		paymentType: "Способ оплаты",
		orderLimitAuthor: "Предел заказа {{author}} {{currency}}",
		orderLimit: "Лимит заказа",
		dataNotFound: "Данные не найдены",
		confirmOrder: "Чтобы завершить заказ, нажмите",
		exchangeInfo: "Если вы платите в иностранной валюте, ваша сдача будет выдана в турецких лирах.",
		deliveryInfo: "Для правильной доставки вашего заказа, убедитесь, что вы правильно указали свое имя и номер комнаты.",
		weather: "Погодные условия",

		totalEuro: "Общая сумма (евро)",
		totalDollar: "Общая сумма (доллар)",
		totalTL: " Общая сумма (TL)",
		diss: "Скидка на все товары только сегодня!",

		continue: "Продолжать",
		cashCurrency: "выберите валюту",
		other: "Другие категории",

		preparing: "Ваш заказ готовится Приятного аппетита...",

		notValid: "Введите имя и номер комнаты",

		searchHome: "Поиск ...",
		notfound: "данные не найдены",

		searchAuthor: "Найти {{author}}",
		useDiscountCode: "Используйте свой код скидки",
		discount: "Скидка",
		discountAuthor: "Скидка ({{author}})",
		products: "Продукты",
		append: "Добавить",
		code: "Код",
		checkAuthor: "проверьте {{author}}",
		"discount-note": "Вы можете проверить свой код скидки в этом разделе и использовать его при необходимости",
		"discount-code-details": "Вы предоставили код — {{code}}. Имя этого кода скидки — {{name}}, и его стоимость составляет {{amount}} % скидки",
		"back-to-menu": "Вернуться в меню",
		bankErrorTitle: "Ошибка платежа",
		bankErrorDescription: "Проблема с оплатой. Пожалуйста, проверьте данные вашей карты и повторите попытку",
		minimumCart: "минимум : {{author}}",
		orderNow: "Заказать сейчас",
		AppointmentDaySelection: "Выбор дня встречи",
		paymentText: "Оплата наличными за заказы не принимается",
		baklavaSpecialOrderPopup: "Пахлава в подарок по вашему индивидуальному заказу",
		baklavaTitle: "попробуй традицию",
		baklavaDescFirst: "Испытайте радость",
		baklavaDescSecond: "БЕСПЛАТНАЯ ПХЛАВА",
		baklavaDescThird: "с каждым заказом, который вы размещаете сегодня!",
		apply: "применять",
		networkDryTitle: "Меры предосторожности перед химчисткой",
		networkDrySubtitle:
			"Эта информация четко излагает, на что наши гости должны обратить внимание перед сдачей своих вещей в химчистку. Пожалуйста, прочитайте внимательно.",
		warning: "Предупреждение",
		networkDryWarning:
			"Продукты, которые вы доставляете в течение дня, будут доставлены вам <underline>до 17:30 на следующий день</underline>. Продукты <underline>не будут доставлены в тот же день.</underline>",

		networkDryDesc1:
			"Проверка ярлыков: Проверьте ярлыки по уходу на ваших вещах. Убедитесь, что они подходят для химчистки и следуйте инструкциям по стирке. Если вы сдаете вещи, которые не подходят для химчистки, любые возможные проблемы будут на ответственности потребителя.",
		networkDryDesc2:
			"Извлечение ценных предметов: Не оставляйте ценные вещи (такие как часы, ожерелья, деньги и т.д.) в карманах или скрытых отделениях вашей одежды.",
		networkDryDesc3:
			"Время доставки: Ваши вещи будут доставлены вам в 17:30 на следующий день после получения. Если у вас есть срочные потребности, пожалуйста, укажите это заранее. (Укажите в разделе примечаний к заказу.)",
		networkDryDesc4: "Условия доставки: Не сдавайте вещи с личными вешалками и чехлами.",
		networkDryDesc5:
			"Обнаружение пятен: Если на ваших вещах есть пятна, определите их и сообщите об этом в химчистку. Знание, что это за пятно, может сделать процесс чистки более эффективным. (Укажите в разделе примечаний к заказу.)",
		networkDryDesc6:
			"Особые инструкции: Если какая-либо часть вашей одежды особенно чувствительна или если вы не хотите, чтобы применялись определенные процессы, четко укажите это. (Укажите в разделе примечаний к заказу.)",
		networkDryDesc7:
			"Проверка износа и повреждений: Перед сдачей вещей проверьте их на наличие износа, разрывов или повреждений. Сообщите об этом в химчистку, чтобы были приняты дополнительные меры предосторожности. (Укажите в разделе примечаний к заказу.)",
		networkDryDesc8: "Удаление аксессуаров: Если на вашей одежде есть съемные аксессуары (такие как ремни, броши и т.д.), удалите их перед сдачей.",
		networkDryDesc9:
			"Выцветание: Проверьте, подвержена ли ваша одежда выцветанию, и если да, сообщите об этом в химчистку. (Укажите в разделе примечаний к заказу.)",
		networkDryCheckedLabel: "Я прочитал, понял и согласен с вышеупомянутыми инструкциями.",
	},
};

export const ru = baseStrings;
