import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";

const UtmSourceContext = createContext();
export const useUtmSource = () => useContext(UtmSourceContext);

const name = "utmSource";
const initialState = {
	value: "",
	isCatalog: false,
	isMagnet: false,
	userId: false,
	legalAge: false,
	language: null,
	visibledPopup: {},
	networkDry: false,
};

function getInitialState() {
	const data = localStorage.getItem(name);
	return data ? JSON.parse(data) : { ...initialState };
	//  "e3a427f6-7821-4f2b-be22-012effb8de6b"
}

export default function UtmSourceProvider({ children }) {
	const [data, setData] = useState(getInitialState);

	useEffect(() => {
		localStorage.setItem(name, JSON.stringify(data));
		console.log("utmData ===>", data);
	}, [data]);

	const initial = () => setData({});

	const handleSetData = (x) =>
		setData({
			value: x,
			isCatalog: x === "catalog",
			isMagnet: x === "magnet",
		});

	const setUserId = (value) => {
		setData((x) => ({
			...x,
			userId: value,
		}));
	};

	const setLanguage = (value) => {
		setData((x) => ({
			...x,
			language: value,
		}));
	};
	const setNetworkDry = (value) => {
		setData((x) => ({
			...x,
			networkDry: value,
		}));
	};
	const setLegalAge = (value) => {
		setData((x) => ({
			...x,
			legalAge: value,
		}));
	};
	const setVisibledPopup = (name) => {
		setData((x) => ({
			...x,
			visibledPopup: {
				...(x?.visibledPopup || {}),
				[name]: true,
			},
		}));
	};
	const initialVisibledPopup = (name) => {
		setData((x) => ({
			...x,
			visibledPopup: {},
		}));
	};

	return (
		<UtmSourceContext.Provider
			value={{ data, setData: handleSetData, setLegalAge, initial, setUserId, setLanguage, setNetworkDry, setVisibledPopup, initialVisibledPopup }}>
			{children}
		</UtmSourceContext.Provider>
	);
}
