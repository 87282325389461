import { useEffect } from "react";

const useClickTarget = (ref, toggle, handleToggle) => {
  const handleUserClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleToggle();
    }
  };

  useEffect(() => {
    if (toggle) {
      document.addEventListener("click", handleUserClick);
      return () => document.removeEventListener("click", handleUserClick);
    }
  });
};

export default useClickTarget;
