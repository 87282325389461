import React, { createContext, useState, useContext, useEffect } from "react";
import { handleAddProduct, handleChangeCount, handleRemoveProduct } from "./helperFunc";
import ModalMelo from "components/ModalMelo";
import ModalBox from "reuse/ModalBox";

const CartContext = createContext();
export const useCarts = () => useContext(CartContext);

function getInitialState() {
	const notes = localStorage.getItem("basket");
	return notes
		? JSON.parse(notes)
		: {
				shoppingCart: [],
				total: 0,
				object: {},
		  };
}

export default function CartProvider({ children }) {
	const [data, setData] = useState(getInitialState);
	const [showMelo, setShowMelo] = useState(false);
	const [selectFinalProduct, setSelectFinalProduct] = useState(false);

	const handleSetMelo = (cart, meloAppointmentDate) => {
		// cart.meloAppointmentDate = meloAppointmentDate
		console.log("* * * handleSetMelo", { cart, meloAppointmentDate });
		setData(handleAddProduct({ state: data, data: cart, meloAppointmentDate }));
		setShowMelo(false);
	};

	useEffect(() => {
		localStorage.setItem("basket", JSON.stringify(data));
	}, [data]);

	console.log("* * * CartProvider : ", { data });

	const addCart = (cart) => {
		// console.log("* * * CartProvider : ", { cart });

		if (cart?.isCampaign) {
			cart = { ...cart, oldPrice: cart?.price, price: 0 };
		}
		if (cart.isMeloProduct) {
			setSelectFinalProduct(cart);
			setShowMelo(true);
		} else {
			const isToCart = data?.object?.[cart?.id];
			if (isToCart) addCount(cart, 1);
			else setData(handleAddProduct({ state: data, data: cart }));
		}
	};
	const addCount = (cart, count) => {
		// console.log("* * * CartProvider :", { cart, count });
		if (count == undefined) count = cart?.count + 1;
		if ((cart?.promotionCodes || cart?.isCampaign) && count > 1) count = 1;
		setData(handleChangeCount({ state: data, cart, count }));
	};
	const removeItem = (cart) => setData(handleRemoveProduct({ state: data, cart }));

	const initial = () =>
		setData({
			shoppingCart: [],
			total: 0,
			object: {},
		});

	return (
		<CartContext.Provider value={{ cart: data, addCart, addCount, removeItem, initial }}>
			{children}
			<ModalMelo
				{...{
					show: showMelo,
					setShow: setShowMelo,
					handleSubmit: handleSetMelo,
					data: selectFinalProduct,
				}}
			/>
		</CartContext.Provider>
	);
}
