const baseStrings = {
	translation: {
		home: " Page d'accueil ",
		menu: " Menu ",
		opportunity: " Spécial ",
		opportunityProducts: " Offres spéciales ",
		payment: " Paiement ",
		back: " Retour ",
		yourName: " Votre nom ",
		roomNumber: " Numéro de chambre ",
		deliveryInfo: " Informations sur les invités ",
		yourDeliveryInfo: " Informations sur les invités ",
		paymentInfo: " Informations sur le paiement ",
		productTotal: " Total du produit ",
		serviceFee: " Frais de service ",
		total: " Montant total ",
		online: " En ligne ",
		card: "Carte de crédit à la livraison",
		myCart: " Mon panier ",
		cash: " Espèces ",
		note: " Note ",
		delivery: " Livraison ",
		payment: "Paiement",
		orderNote: " Saisissez votre note de commande ",
		completeOrder: " Complétez la commande ",
		enjoyYourMeal: " Bon appétit!",
		yourOrderReceived: " Votre commande reçue ",
		// completeOrder: " Votre commande est terminée ",
		hello: " Salut ",
		uuiError: " Vous pouvez entrer simplement en scannant le code QR.",
		successful: " Succès ",
		productAddToCart: " Produit ajouté au panier!",
		addToBasket: " Ajouter au panier ",
		removeFromBasket: " Retirer du panier ",
		productTitle: " SOINS PERSONNELS ",
		itemNotFound: " Article introuvable ",
		completeTheOrder: " Veuillez compléter la commande ",
		selectThePayment: " Veuillez sélectionner le type de paiement ",
		paymentType: " Mode de paiement ",
		orderLimitAuthor: " Limite de l'ordre {{auteur}} {{devise}}",
		orderLimit: " Limite de commande ",
		dataNotFound: " Aucune donnée n'a été trouvée ",
		confirmOrder: " Tapez pour terminer la commande ",
		exchangeInfo: " Le changement est donné en TL ",
		deliveryInfo: " Pour que votre commande soit livrée correctement, veillez à écrire complètement votre nom et votre numéro de chambre..",
		weather: " Météo ",

		totalEuro: " Montant total (Euro)",
		totalDollar: " Montant total (Dollars)",
		totalTL: " Montant total (TL)",
		diss: " Remise sur tous les produits spéciaux pour aujourd'hui!",

		continue: "Continuer",
		cashCurrency: "Sélectionnez la devise",
		other: "Autres catégories",
		preparing: "Votre commande est en cours de préparation Bon appétit...",

		notValid: "Entrez le nom et le numéro de chambre",

		searchHome: "Recherche ...",
		notfound: "données introuvables",

		searchAuthor: "Rechercher {{author}}",
		useDiscountCode: "Utilisez votre code de réduction",
		discount: "Remise",
		discountAuthor: "Remise ({{author}})",
		products: "Produits",
		append: "Ajouter",
		code: "Code",
		checkAuthor: "vérifier {{author}}",
		"discount-note": "Vous pouvez vérifier votre code de réduction dans cette section et l'utiliser si nécessaire",
		"discount-code-details":
			"Le code que vous avez fourni est {{code}}. Le nom de ce code de réduction est {{name}} et il a une valeur de {{amount}} % de réduction",
		"back-to-menu": "Retour au menu",
		bankErrorTitle: "Le paiement a échoué",
		bankErrorDescription: "Il y a un problème avec le paiement. Veuillez vérifier les informations de votre carte et réessayer",
		minimumCart: "le minimum : {{author}}",
		orderNow: "Commandez maintenant",
		AppointmentDaySelection: "Sélection du jour du rendez-vous",
		paymentText: "Le paiement en espèces n'est pas accepté pour les commandes",
		baklavaSpecialOrderPopup: "Cadeau Baklava pour vos commandes spéciales",
		baklavaTitle: "goûtez la tradition",
		baklavaDescFirst: "Expérimentez la joie de",
		baklavaDescSecond: "BAKLAVA GRATUIT",
		baklavaDescThird: "avec chaque commande que vous passez aujourd'hui !",
		apply: "appliquer",
		networkDryTitle: "Précautions à prendre avant le nettoyage à sec",
		networkDrySubtitle:
			"Ces informations décrivent clairement ce à quoi nos invités doivent prêter attention avant de confier leurs articles pour le nettoyage à sec. Veuillez lire attentivement.",
		warning: "Avertissement",
		networkDryWarning:
			"Les produits que vous livrez pendant la journée vous seront livrés <underline>avant 17h30 le lendemain</underline>. Les produits <underline>ne seront pas livrés le même jour.</underline>",

		networkDryDesc1:
			"Inspection des étiquettes: Examinez les étiquettes d'entretien de vos articles. Assurez-vous qu'ils sont adaptés au nettoyage à sec et suivez les instructions de nettoyage. Si vous remettez des articles qui ne conviennent pas au nettoyage à sec, tout problème potentiel qui pourrait survenir sera la responsabilité du consommateur.",
		networkDryDesc2:
			"Notification des objets de valeur: Ne laissez pas d'objets de valeur (comme des montres, colliers, argent liquide, etc.) dans les poches ou les compartiments cachés de vos articles.",
		networkDryDesc3:
			"Délai de livraison: Vos articles vous seront livrés à 17h30 le lendemain de leur réception. Si vous avez des besoins urgents, veuillez le préciser à l'avance. (Précisez-le dans la section des notes de commande.)",
		networkDryDesc4: "Conditions de livraison: Ne remettez pas vos articles avec des cintres et des sacs à vêtements personnels.",
		networkDryDesc5:
			"Détection des taches: Si vos articles présentent des taches, identifiez-les et informez-en le nettoyeur à sec. Connaître la nature de la tache peut rendre le processus de nettoyage plus efficace. (Précisez-le dans la section des notes de commande.)",
		networkDryDesc6:
			"Instructions spéciales: Si une partie de vos articles est particulièrement sensible ou si vous ne souhaitez pas qu'un certain processus soit appliqué, veuillez le préciser clairement. (Précisez-le dans la section des notes de commande.)",
		networkDryDesc7:
			"Inspection de l'usure et des dommages: Avant de remettre vos articles, vérifiez s'il y a des signes d'usure, des déchirures ou des dommages. Informez-en le nettoyeur à sec pour que des précautions supplémentaires soient prises. (Précisez-le dans la section des notes de commande.)",
		networkDryDesc8:
			"Retrait des accessoires: Si vos articles comportent des accessoires amovibles (comme des ceintures, broches, etc.), retirez-les avant de remettre vos articles.",
		networkDryDesc9:
			"Décoloration: Vérifiez si vos articles sont sujets à la décoloration et, si c'est le cas, informez-en le nettoyeur à sec. (Précisez-le dans la section des notes de commande.)",
		networkDryCheckedLabel: "J'ai lu, compris et j'accepte les instructions mentionnées ci-dessus.",
	},
};

export const fr = baseStrings;
