const baseStrings = {
	translation: {
		home: "مسكن",
		menu: "قائمة الطعام ",
		opportunity: "عروض ",
		opportunityProducts: "عروض خاصة",
		payment: "دفع",
		back: "Back",
		yourName: "اسمك ",
		roomNumber: "رقم الغرفة",
		deliveryInfo: "معلومات التوصيل ",
		yourDeliveryInfo: "معلومات التسليم الخاصة بك",
		paymentInfo: "معلومات الدفع",
		productTotal: "إجمالي المنتج",
		serviceFee: "رسوم الخدمة",
		total: " المجموع ",
		online: "متصل",
		card: "بطاقة الائتمان عند التسليم",
		myCart: "عربة التسوق الخاصة بي",
		cash: "نقدي",
		note: "ملاحظة",
		delivery: "توصيل",
		payment: "دفع",
		orderNote: "أدخل مذكرة طلبك",
		completeOrder: "اكمل الطلب",
		enjoyYourMeal: "أتمنى لك وجبة شهية!",
		yourOrderReceived: "تم استلام طلبك",
		// completeOrder: "تم اكتمال طلبك",
		hello: "أهلا",
		uuiError: "يمكنك الدخول فقط عن طريق مسح رمز الاستجابة السريعة",
		successful: "ناجح",
		productAddToCart: "تمت إضافة المنتج إلى عربة التسوق!",
		addToBasket: "اضف الى السلة",
		removeFromBasket: "أخرجه من السلة",
		productTitle: "رعاية شخصية",
		itemNotFound: "العنصر غير موجود",
		completeTheOrder: "الرجاء إكمال الطلب",
		selectThePayment: "الرجاء تحديد نوع الدفع",
		paymentType: "نوع الدفع",
		orderLimitAuthor: "حد الطلب هو {{author}} {{currency}}",
		orderLimit: "حد الطلب",
		dataNotFound: "لم يتم العثور على بيانات",
		confirmOrder: "اضغط لإكمال الطلب",
		exchangeInfo: "إذا قمت بالدفع بالعملة الأجنبية، سيتم إعطاؤك الصرف بالليرة التركية.",
		deliveryInfo: "لتسليم الطلب بشكل صحيح ، يرجى التأكد من كتابة الاسم وتفاصيل الغرفة بشكل صحيح",
		totalEuro: "إجمالي اليورو",
		totalDollar: "إجمالي الدولار",
		totalTL: "اجمالي الليرة التركية",

		continue: "يكمل",
		cashCurrency: "اختر العملة",
		other: "فئات أخرى",

		preparing: "يتم تحضير طلبك الآن استمتع بوجبتك ...",

		notValid: "أدخل الاسم واللقب ورقم الغرفة",

		searchHome: "اتصلي...",
		notfound: "لم يتم العثور على بيانات",

		searchAuthor: "ابحث عن {{author}}",
		useDiscountCode: "استخدم رمز الخصم الخاص بك",
		discount: "الخصم",
		discountAuthor: "الخصم ({{author}})",
		products: "المنتجات",
		append: "إلحاق",
		code: "الكود",
		checkAuthor: "تحقق من {{author}}",
		"discount-note": "يمكنك التحقق من رمز الخصم الخاص بك من هذا القسم واستخدامه إذا لزم الأمر",
		"discount-code-details": "الكود الذي قدمته هو {{code}}. اسم رمز الخصم هذا هو {{name}} وقيمته {{amount}}% خصم",
		"back-to-menu": "العودة إلى القائمة",
		BankErrorTitle: "فشل الدفع",
		BankErrorDescription: "هناك مشكلة في الدفع. يرجى التحقق من معلومات بطاقتك والمحاولة مرة أخرى",
		minimumCart: "الحد الأدنى : {{author}}",
		orderNow: "اطلب الآن",
		paymentText: "لا يتم قبول الدفع النقدي للطلبات",
		baklavaSpecialOrderPopup: "هدية البقلاوة لطلباتك الخاصة",
		baklavaTitle: "ذوق التقليد",
		baklavaDescFirst: "اختبر متعة",
		baklavaDescSecond: "البقلاوة المجانية",
		baklavaDescThird: "مع كل طلب تقدمه اليوم!",
		apply: "قدم",
		networkDryTitle: "الاحتياطات الواجب اتخاذها قبل التنظيف الجاف",
		networkDrySubtitle: "توضح هذه المعلومات بوضوح ما يجب على ضيوفنا الانتباه إليه قبل تسليم ملابسهم للتنظيف الجاف. يرجى قراءة التعليمات بعناية.",
		warning: "تحذير",
		networkDryWarning:
			"سيتم تسليم المنتجات التي تسلمها خلال اليوم <underline>بحلول الساعة 17:30 من اليوم التالي</underline>. المنتجات <underline>لن يتم تسليمها في نفس اليوم.</underline>",

		networkDryDesc1:
			"فحص الملصقات: افحص ملصقات العناية على ملابسك. تأكد من أنها مناسبة للتنظيف الجاف واتباع تعليمات التنظيف. إذا قمت بتسليم ملابس غير مناسبة للتنظيف الجاف، فإن أي مشكلات قد تنشأ ستكون على مسؤولية المستهلك.",
		networkDryDesc2: "الإبلاغ عن الأشياء الثمينة: لا تترك أشياء ثمينة (مثل الساعات، القلائد، النقود، إلخ) في جيوب الملابس أو في الأماكن المخفية.",
		networkDryDesc3:
			"وقت التسليم: سيتم تسليم ملابسك إليك في الساعة 5:30 مساءً في اليوم التالي من استلامها. إذا كانت لديك حاجة ملحة، يرجى الإشارة إلى ذلك مسبقًا. (حدد ذلك في قسم ملاحظات الطلب.)",
		networkDryDesc4: "شروط التسليم: لا تقم بتسليم ملابسك مع الشماعات والأكياس الشخصية.",
		networkDryDesc5:
			"كشف البقع: إذا كانت هناك بقع على ملابسك، حددها وأخبر المسؤول عن التنظيف الجاف بها. معرفة نوع البقع يمكن أن يساعد في جعل عملية التنظيف أكثر فعالية. (حدد ذلك في قسم ملاحظات الطلب.)",
		networkDryDesc6:
			"تعليمات خاصة: إذا كانت أي جزء من ملابسك حساسًا بشكل خاص أو إذا كنت لا ترغب في تطبيق عملية معينة، يرجى توضيح ذلك بوضوح. (حدد ذلك في قسم ملاحظات الطلب.)",
		networkDryDesc7:
			"فحص التآكل والضرر: قبل تسليم ملابسك، تحقق من أي تآكل أو تمزقات أو أضرار. أخبر مسؤول التنظيف الجاف بذلك لضمان اتخاذ الاحتياطات الإضافية. (حدد ذلك في قسم ملاحظات الطلب.)",
		networkDryDesc8:
			"إزالة الإكسسوارات: إذا كانت ملابسك تحتوي على إكسسوارات قابلة للإزالة (مثل الأحزمة، الدبابيس، إلخ)، قم بإزالتها قبل تسليم الملابس.",
		networkDryDesc9:
			"تلاشي اللون: تحقق مما إذا كانت ملابسك عرضة لتلاشي اللون، وإذا كانت كذلك، أخبر مسؤول التنظيف الجاف. (حدد ذلك في قسم ملاحظات الطلب.)",
		networkDryCheckedLabel: "لقد قرأت وفهمت وأوافق على التعليمات المذكورة أعلاه.",
	},
};

export const ar = baseStrings;
