/*eslint-disable */
// function localStorageSetItem({ param }) {

import Decimal from "decimal.js";

//   localStorage.setItem("basket", JSON.stringify(param));
// }
// =============================  ADD_PRODUCT
export function handleAddProduct({ state, data, meloAppointmentDate }) {
  let newDate = new Date();
  if (state.object[data.id]) return handleChangeCount({ state, cart: data });
  let shoppingCart = [
    ...state.shoppingCart,
    { data, count: 1, time: newDate.toISOString(), meloAppointmentDate },
  ];
  let returnData = {
    ...state,
    shoppingCart,
  };

  return handleTotal({ state: returnData });
}
// =============================  ADD_SHOPPING_CART
export function handleAddShoppingCart({ state, newShoppingCart }) {
  let returnData = {
    ...state,
    shoppingCart: newShoppingCart,
  };

  return handleTotal({ state: returnData });
}
// =============================  REMOVE_PRODUCT
export function handleRemoveProduct({ state, cart }) {
  let returnData;
  let newShoppingCart = state?.shoppingCart?.filter((x) => {
    if (x?.data?.id !== cart?.id) return x;
  });
  returnData = { ...state, shoppingCart: newShoppingCart };
  return handleTotal({ state: returnData });
}
// =============================  PRICE_INCREASE
export function handleChangeCount({ state, cart, count }) {
  // console.log("handleChangeCount", { state, cart, count });
  let returnData;
  let increaseOrderData = state.shoppingCart.map((x) => {
    if (x.data.id === (cart.id || cart?.data?.id)) {
      // console.log("count log", { state, cart, count });

      x.count = count ? count : count === 0 ? 1 : (x.count || 0) + 1;
    }
    return x;
  });
  returnData = { ...state, shoppingCart: increaseOrderData };

  return handleTotal({ state: returnData });
}
// =============================  TOTAL_GOODS
export function handleTotal({ state }) {
  let returnData;
  const total = () => {
    const reducer = (accumulator, currentValue) => {
      // console.log("* * * handleTotal :", accumulator, currentValue.count);

      return (new Decimal(accumulator || 0).add(currentValue.count || 0))?.toString()
    }
    // accumulator + currentValue.count;
    if (state.shoppingCart.length > 0) {
      return state.shoppingCart.reduce(reducer, 0);
    }
  };
  returnData = { ...state, counts: total() };
  console.log("* * * handleTotal returnData:", returnData);

  return handleTotalPrice({ state: returnData });
}

// =============================  TOTAL_PRICE
export function handleTotalPrice({ state }) {
  let returnData;

  let object = {};
  const total = () => {
    const reducer = (accumulator, currentValue) => {
      object[currentValue.data?.id] = currentValue.data;
      // console.log("* * * handleTotalPrice :", accumulator, currentValue.data.price, currentValue.count);
      return new Decimal(accumulator || 0).add(new Decimal(currentValue.data.price || 0).mul(currentValue.count || 0))?.toString()
      // return accumulator + currentValue.data.price * currentValue.count;
    };
    if (state.shoppingCart.length > 0) {
      return state.shoppingCart.reduce(reducer, 0);
    }
  };
  returnData = { ...state, prices: total(), object };
  console.log("* * * handleTotalPrice returnData:", returnData);

  // localStorageSetItem({ param: returnData });
  return returnData;
}
