import { getDiscount, getDolarRate, getHotel, getHotelMenu, getRecommendedProduct } from "api/api";
import Decimal from "decimal.js";
import RecommandedProduct from "components/RecommandedProduct";
import { SYMBOLS_DATA_OBJECT, UUID_KY } from "enumeration";
import { useMemo } from "react";
import { useState, useEffect } from "react";
import { Alert, Badge, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { useCategory } from "_context/category/provider";
import { useCarts } from "_context/shoppingCart/cartProvider";
import { useDiscount } from "_context/discount/discountProvider";
import { useUuid } from "_context/uuid/uuidProvider";
import "./style.scss";
import { pageView } from "../../GTM";
import NavBar from "../navbar";
import PageNavbar from "components/PageNavbar/pageNavbar";
import ProductCard from "components/isCartCard";
import EmptyCart from "components/emptyCart";
import styles from "./index.module.scss";
import ModalLayout from "components/ModalLayout";
import Discount from "./Discount";
import { useUtmSource } from "_context/utmSource/uuidProvider";
import NetworkDry from "./NetworkDry";

export const calculateValueWithPercent = (totalValue, partialValue) => {
	return new Decimal(totalValue || 0).div(100).mul(partialValue);
	//  (totalValue / 100) * partialValue;
};

const Cart = (props) => {
	// console.log("props =====>", props);
	const [status, setStatus] = useState(["200"]);
	const [show, setShow] = useState(false);
	const [modalToggleNetworkDry, setModalToggleNetworkDry] = useState(false);

	const { t, i18n } = useTranslation();

	const { addCount, cart, removeItem, addCart } = useCarts();
	const discountState = useDiscount();
	// console.log({ discountState });
	const { data: catData } = useCategory();

	const { data: uuid } = useUuid();
	const { data: utm, setNetworkDry } = useUtmSource();

	const { data: hotel } = useQuery("hotel", () => getHotel(uuid));
	const { data: currency } = useQuery("currency", () => getDolarRate());

	const { data, isError, error, isLoading, isFetching, refetch } = useQuery(["recommended"], () => getRecommendedProduct(uuid), {
		// cacheTime: 0,
		// onSuccess: handleSuccess,
		retry: false,
	});

	const discountMutation = useMutation(
		"discount",
		(data) => {
			// console.log("* * * discount :", { data });
			return getDiscount(data);
		},
		{
			onSuccess: (data) => {
				discountState.setData({
					name: data.name,
					code: data.code,
					amount: data.amount,
				});
				// console.log("* * * discount onSuccess:", { data });
			},
		}
	);

	const isNetworkDry = useMemo(() => {
		return !![...(cart?.shoppingCart || [])]?.find((x) => x?.data?.isNetworkDry);
	}, [cart]);
	let checkNetworkDry = !utm.networkDry && isNetworkDry;

	// console.log({ checkNetworkDry, networkDry: utm.networkDry, isNetworkDry });

	const handleToggleNetworkDryModal = () => setModalToggleNetworkDry((p) => !p);

	const menuCurrency = hotel?.region?.menuCurrency;
	let currentCurrency = SYMBOLS_DATA_OBJECT[menuCurrency];
	let symbol = currentCurrency?.label;

	const handleRemoveCart = (item) => {
		removeItem(item.data);
		// console.log("removeItem ====>", item);
		// swal(t("successful"), t("removeFromBasket"), "success");
	};

	let orderLimit = hotel?.orderLimit;

	if (currentCurrency?.isTry) orderLimit = new Decimal(orderLimit || 0).mul(new Decimal(currency?.euro || 0).mul(1.08));
	// if (currentCurrency?.isEur) orderLimit = new Decimal(orderLimit || 0).div((currency?.euro || 0) * 1.08);
	// else if (currentCurrency?.isDollar) orderLimit = new Decimal(orderLimit || 0).div(currency?.dollar);
	orderLimit = orderLimit && +orderLimit;

	// console.log({ aaaaaaaa: ((orderLimit) * currency?.euro) / currency?.dollar, bbbbb: +new Decimal(orderLimit || 0).div(currency?.euro) });

	const fee = 12.5;
	const serviceFee = hotel?.serviceCharge ? calculateValueWithPercent(cart?.prices, fee) : 0;

	useEffect(() => {
		pageView(window?.location?.pathname);
		// console.log("pathName", window.location.pathname);
	}, []);

	const limitText = (text, limit) => {
		if (text.length <= limit) {
			return text;
		}
		return text.slice(0, limit) + "...";
	};

	const handleDiscount = () => {
		setShow(true);
	};

	const handleToggleDiscount = () => {
		setShow((p) => !p);
	};

	let total = new Decimal(cart.prices || 0);
	let totalWithService = total;
	if (serviceFee) {
		total = total.add(serviceFee);
		totalWithService = total;
	}
	if (discountState?.data?.amount) total = total.sub(total.div(100).mul(discountState?.data?.amount));
	total = total.toString();

	let isDiscount = discountState?.data?.amount != false && discountState?.data?.amount >= 0;

	let reverseCart = useMemo(() => {
		return [...(cart?.shoppingCart || [])]?.reverse();
	}, [cart]);

	const orderLimitValid = orderLimit ? orderLimit <= +totalWithService : true;
	const disable = !orderLimitValid;
	// console.log({ totalWithService: +totalWithService, orderLimit, currency, disable, symbol, cart, hotel, SYMBOLS_DATA_OBJECT });
	const filterData = useMemo(() => {
		return data?.result?.filter((x) => !cart?.object[x?.id]);
	}, [cart, data]);
	return (
		<>
			<ModalLayout {...{ show, setShow, title: t("discount") }}>
				<Discount toggle={handleToggleDiscount} />
			</ModalLayout>

			<ModalLayout
				{...{
					show: modalToggleNetworkDry,
					setShow: setModalToggleNetworkDry,
					title: t("networkDryTitle"),
					className: { title: styles.networkDryTitle },
				}}>
				<NetworkDry toggle={handleToggleNetworkDryModal} />
			</ModalLayout>

			<div className="">
				<PageNavbar
					text={t("myCart")}
					path={catData?.child?.id ? `/product/${catData?.child?.id}` : catData?.parent?.id ? `/product/${catData?.parent?.id}` : "/"}
				/>
				<div className={styles.body}>
					{!cart?.shoppingCart?.length ? (
						// <div className=" p-4">
						//   <Alert
						//     className="d-flex align-items-center"
						//     key={"secondary"}
						//     variant={"secondary"}
						//   >
						//     <div className="mr-2">{t("itemNotFound")}</div>
						//     <Link to="/">
						//       <Button variant="danger" size="sm">
						//         {t("Home")}
						//       </Button>
						//     </Link>
						//   </Alert>
						// </div>
						<div className={[styles.cartItems, !filterData?.length && styles.full].join(" ")}>
							<EmptyCart />
						</div>
					) : (
						<div className={[styles.cartItems, !filterData?.length && styles.full].join(" ")}>
							{reverseCart?.map((x) => {
								const isTranslate = {};
								x?.data?.translations?.forEach((y) => (isTranslate[y?.language] = y));
								// const textTranslate = isTranslate[i18n?.language];
								// console.log("newCard", cart?.shoppingCart);
								return <ProductCard className="mt-4" data={x} key={x?.data} handleRemoveCart={handleRemoveCart} symbol={symbol} />;
							})}
						</div>
					)}

					{/* {<div className="empty-space" />} */}

					<div className="area fixed-bottom-r ">
						<div className={["shadow bg-white rounded  clearfix comeTop flex-direction-column border-top", styles.calculatorBox].join(" ")}>
							<div className="p-2">
								<span className=" ">{t("products")}</span>
								<span className="float-right">
									{numberWithCommas(changePriceFormat(cart.prices))}
									{symbol}
								</span>
							</div>
							<div className="p-2">
								<span className=" " data-lang="hizmet_bedeli">
									{t("serviceFee")}
								</span>
								<span className="float-right">
									{isNaN(serviceFee) ? "0" : numberWithCommas(changePriceFormat(serviceFee))}
									{symbol}
								</span>
							</div>
							<div
								className={[styles.discount, "p-2", isDiscount && styles.active].join(" ")}
								onClick={() => {
									!isDiscount && handleDiscount();
								}}>
								<i className={["fa-solid fa-tag", styles.icon].join(" ")}></i>
								<span className={styles.label} data-lang="hizmet_bedeli">
									{isDiscount ? t("discount") : t("useDiscountCode")}
								</span>
								<span className="float-right">
									{isDiscount ? (
										numberWithCommas(discountState?.data?.amount) + "%"
									) : (
										<Badge className="p-1" bg="success">
											{t("apply")}
											{/* {t("append")} */}
										</Badge>
									)}
								</span>
							</div>
						</div>

						{filterData?.length ? <RecommandedProduct symbol={symbol} /> : ""}
					</div>
					{cart?.shoppingCart?.length ? (
						<div className="continue-button-container" disabled={disable}>
							<Link
								to={{
									pathname: disable ? "/cart" : `/payment`,
									// search: `?${[UUID_KY]}=${uuid}`,
								}}
								className={`continue-button btn-block btn-lg ${disable ? styles["disabled"] : ""}`}
								style={{ color: "#fff" }}
								id="odemeYap"
								data={status}
								disabled={disable}
								onClick={
									checkNetworkDry
										? (e) => {
												e.preventDefault();
												handleToggleNetworkDryModal();
										  }
										: null
								}>
								<div className="button-container">
									<button className="button button-left">
										{disable ? t("minimumCart", { author: (orderLimit || 0)?.toFixed?.(2) + symbol }) : t("continue")}
									</button>
									<button className="button button-right d-flex flex-column py-0 align-items-center justify-content-center">
										{/* {cart && cart.prices ? cart.prices.toFixed(2) : "0"}{symbol} */}
										{isDiscount && cart && cart.prices ? (
											<span className={styles.beforePrice}>{numberWithCommas(changePriceFormat(totalWithService))}</span>
										) : (
											""
										)}
										<div className={["d-flex", isDiscount && "text-success"].join(" ")}>
											<span> {numberWithCommas(changePriceFormat(total))} </span>
											<span> {symbol}</span>
										</div>
									</button>
								</div>
							</Link>
						</div>
					) : (
						<div className="continue-button-container">
							<div
								className={`continue-button btn-block btn-lg disabled`}
								style={{ color: "#fff" }}
								id="odemeYap"
								// data={status}
							>
								<div className="button-container">
									<button className="button button-left-disabled">{t("continue")}</button>
									<button className="button button-right d-flex flex-column py-0 align-items-center justify-content-center">
										{/* {cart && cart.prices ? cart.prices.toFixed(2) : "0"}{symbol} */}
										{isDiscount && cart && cart.prices ? (
											<span className={styles.beforePrice}>{numberWithCommas(changePriceFormat(totalWithService))}</span>
										) : (
											""
										)}
										<div className={["d-flex", isDiscount && "text-success"].join(" ")}>
											<span> {numberWithCommas(changePriceFormat(total))} </span>
											<span> {symbol}</span>
										</div>
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Cart;
