import { useMemo, useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { pageView } from "../../GTM";
import styles from "./index.module.scss";
import { useCarts } from "_context/shoppingCart/cartProvider";
import Skeleton from "react-loading-skeleton";
import ImageFullLoad from "components/ImageFullLoad";

const { useTranslation } = require("react-i18next");

const SearchBarCard = ({ handleRemoveCart, handleAddCart, data, isToCart, symbol, loading }) => {
  const { t, i18n } = useTranslation();
  const isTranslate = useMemo(() => {
    const objData = {};
    data?.translations?.forEach((x) => (objData[x?.language] = x));

    return objData;
  }, [data]);

  const { addCount, cart, removeItem, addCart } = useCarts();

  const textTranslate = isTranslate[i18n?.language];
  // console.log({ isTranslate, textTranslate }, i18n?.language);

  useEffect(() => {
    pageView(window?.location?.pathname);
  }, []);

  const limitText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + "...";
  };

  return (
    <>
      <div
        style={{
          maxWidth: "100%",
        }}
      >
        <div className={`${styles["productCard"]} d-flex flex-row  `}>
          <div className="productCardImageBox">
            {/* <img
              src={data?.images}
              className={styles["CardImage"]}
              alt={data?.name}
            /> */}
            <ImageFullLoad src={data?.images} className="CardImage" alt={data?.name} />
          </div>
          <div className={`${styles["cardBody"]} radius wrappers`}>
            <h4 className="cardText">
              {loading ?
                <Skeleton />
                : limitText(textTranslate?.name || data?.name, 26)}
            </h4>

            <p>{loading ?
              <Skeleton />
              : limitText(textTranslate?.shortDesc || data?.shortDesc, 65)}</p>
            {/* <p>{textTranslate?.desc || data?.desc}</p> */}
            <div className="d-flex flex-row">
              {loading ?
                <Skeleton height={10} width={50} />
                :
                <>
                  {

                    data?.oldPrice ? (
                      <h4 className="orgPrice">
                        {numberWithCommas(changePriceFormat(data?.oldPrice))} {symbol}
                      </h4>
                    ) : (
                      ""
                    )}
                  <h4 className="price">
                    {numberWithCommas(changePriceFormat(data?.price))} {symbol}
                  </h4>
                </>}

            </div>
            {/* {isToCart ? ( */}
            <div className={styles["button-cart"]}>
              {/* ) : ( */}

              {/* )} */}
            </div>

            {/* {isToCart ? (
              <span className="addBasketButtons d-flex align">
                {data?.count > 1 ? (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => addCount(data, data?.count - 1)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => handleRemoveCart(data)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                )}

                <Button className="quantityButton">{data?.count}</Button>
                <button
                  type="button"
                  className=" right inc quantityButtonPlus"
                  id="adetArti"
                  data-urunid={19}
                  onClick={() => addCount(data)}
                >
                  {" "}
                  <i className="fa-solid fa-plus" />
                </button>
              </span>
            ) : (
              <Button
                onClick={() => handleAddCart(data)}
                className="float-end addBasketButton"
              ></Button>
            )} */}
            {!loading ? <div>
              <span
                className={`${isToCart && styles["visible"]} ${styles["showItem"]
                  } d-flex align justify-content-end`}
              >
                {data?.count > 1 ? (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => addCount(data, data?.count - 1)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => handleRemoveCart(data)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                )}

                <Button className="quantityButton">{data?.count}</Button>
                <button
                  type="button"
                  className=" right inc quantityButtonPlus"
                  id="adetArti"
                  data-urunid={19}
                  onClick={() => addCount(data)}
                >
                  {" "}
                  <i className="fa-solid fa-plus" />
                </button>
              </span>
              <Button
                onClick={() => handleAddCart(data)}
                className={`${!isToCart && styles["visible"]}  ${styles["showItem"]
                  } d-flex justify-content-end ${styles["addBasketButton"]}`}
              ></Button>
            </div> : ""}

          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBarCard;
